<script setup lang="ts">
defineProps<{
  columns: Record<string, string>;
  actions?: Record<string, string>;
  data?: any[];
}>();

const emit = defineEmits<{
  (e: 'action', action: string, data: any): void;
}>();

const getDefaultColumnValue = (row: any, column: string) => {
  const value = row[column];

  if (value && typeof value === 'object') {
    if ('name' in value) return value.name;
    if ('description' in value) return value.description;
    return JSON.stringify(value);
  }

  return value ?? `(${column})`;
};
</script>

<template>
  <table class="border-separate border-spacing-5">
    <thead>
      <tr>
        <th v-for="column in columns">{{ column }}</th>
        <th v-for="_ in actions ?? []"></th>
      </tr>
    </thead>
    <tbody v-if="data">
      <tr v-for="row in data">
        <td v-for="(_, column) in columns">
          <slot :name="column" :data="row">
            {{ getDefaultColumnValue(row, column) }}
          </slot>
        </td>
        <td v-for="(actionName, actionKey) in actions ?? []">
          <button @click="emit('action', actionKey, row)" class="underline">
            {{ actionName }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped lang="scss"></style>
