<script setup lang="ts">
import { useApiFetch } from '../composables/use-api-fetch';
import { ref } from 'vue';

const { apiFetch } = useApiFetch();
const loading = ref(false);

async function handleClick() {
  loading.value = true;

  try {
    await apiFetch('/api/accounts/sync', {
      method: 'POST',
    });
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
}
</script>

<template>
  <button
    class="border py-2 px-5 hover:bg-slate-500"
    @click="handleClick"
    :disabled="loading"
  >
    <slot> Sync all Accounts </slot>
  </button>
</template>

<style scoped lang="scss"></style>
