<script setup lang="ts">
import { useApiFetch } from '../composables/use-api-fetch';
import useSWRV from 'swrv';

const props = defineProps<{
  userId: string;
}>();

const emit = defineEmits<{
  (e: 'saved', result: any): void;
}>();

const { apiFetch } = useApiFetch();
const { data: accounts } = useSWRV<any[]>('/api/accounts', apiFetch);

const handleSubmit = async (formData: any) => {
  const result = await apiFetch(`/api/users/${props.userId}/configs`, {
    method: 'POST',
    body: JSON.stringify(formData),
  });
  emit('saved', result);
};
</script>

<template>
  <FormKit
    type="form"
    @submit="handleSubmit"
    :submit-attrs="{ wrapperClass: 'mt-4', inputClass: 'underline' }"
  >
    <FormKit
      type="select"
      name="accountId"
      label="Account"
      :options="
        (accounts ?? []).map((account) => ({
          label: account.name,
          value: account.id,
        }))
      "
      inputClass="w-full bg-gray-800 border border-white px-4 py-2"
    />
  </FormKit>
</template>

<style scoped lang="scss"></style>
