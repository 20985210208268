<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faClipboardCheck, faCopy } from '@fortawesome/free-solid-svg-icons';

defineProps<{
  text?: string;
}>();

const copy = (val: string) => {
  navigator.clipboard.writeText(val);
};
</script>

<template>
  <button @click="copy(text)" class="group" :title="text">
    <FontAwesomeIcon :icon="faCopy" class="group-focus:hidden" />
    <FontAwesomeIcon
      :icon="faClipboardCheck"
      class="hidden group-focus:inline"
    />
  </button>
</template>

<style scoped lang="scss"></style>
