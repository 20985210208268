import { createAuth0 } from '@auth0/auth0-vue';

const authz = createAuth0({
  domain: import.meta.env.VITE_AUTHZ_DOMAIN,
  clientId: import.meta.env.VITE_AUTHZ_CLIENTID,
  authorizationParams: {
    audience: import.meta.env.VITE_AUTHZ_AUDIENCE,
    redirect_uri: window.location.origin,
  },
});

export default authz;
