<script setup lang="ts">
import { computed, ref } from 'vue';
import { useApiFetch } from '../composables/use-api-fetch';
import useSWRV from 'swrv';
import { useRoute } from 'vue-router';
import EmbeddedModal from '../components/EmbeddedModal.vue';
import CreateUserConfigForm from '../components/CreateUserConfigForm.vue';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import EditConfiguration from '../components/EditConfiguration.vue';

const route = useRoute();
const userId = computed(() => route.params.id);

const { apiFetch } = useApiFetch();
const { data: user } = useSWRV<any>(
  () => `/api/users/${userId.value}`,
  apiFetch
);
const { data: configs, mutate } = useSWRV<any[]>(
  () => `/api/users/${userId.value}/configs`,
  apiFetch
);
const { data: apps } = useSWRV<any[]>(() => `/api/apps`, apiFetch);
const { data: permissions } = useSWRV<any[]>(() => `/api/scopes`, apiFetch);

const creating = ref(false);
const editConfig = ref();
const handleAction = (action: string, config: any) => {
  switch (action) {
    case 'delete':
      apiFetch(`/api/users/${userId.value}/configs/${config.id}`, {
        method: 'DELETE',
      }).then(() => mutate());
      break;
    default:
      break;
  }
};
const handleSaved = () => {
  creating.value = false;
  editConfig.value = undefined;
  mutate();
};
</script>

<template>
  <div class="flex justify-between">
    <span class="font-bold"
      >{{ user?.nickname ?? 'User' }} configurations:</span
    >
    <button @click="creating = true">
      <FontAwesomeIcon :icon="faCirclePlus" />
    </button>
  </div>
  <div class="py-4" v-if="apps && permissions">
    <EditConfiguration
      v-for="config in configs"
      :config="config"
      :apps="apps"
      :permissions="permissions"
      @saved="() => mutate()"
    />
  </div>
  <EmbeddedModal :open="creating" @closing="creating = false">
    <div class="m-5">
      <CreateUserConfigForm :user-id="userId" @saved="handleSaved" />
    </div>
  </EmbeddedModal>
</template>

<style scoped lang="scss"></style>
