<script setup lang="ts">
import { useApiFetch } from '../composables/use-api-fetch';
import useSWRV from 'swrv';
import SimpleList from '../components/SimpleList.vue';
import { ref } from 'vue';
import EmbeddedModal from '../components/EmbeddedModal.vue';
import CreateAppForm from '../components/CreateAppForm.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faArrowUpRightFromSquare,
  faCirclePlus,
} from '@fortawesome/free-solid-svg-icons';
import CopyButton from '../components/CopyButton.vue';

const { apiFetch } = useApiFetch();
const { data, mutate } = useSWRV<any[]>('/api/apps', apiFetch);

const creating = ref(false);
const handleCreate = () => {
  creating.value = false;
  mutate();
};
const handleAction = (action: string, app: any) => {
  switch (action) {
    case 'delete':
      if (confirm(`Do you really want to delete app "${app.name}"?`)) {
        apiFetch(`/api/apps/${app.id}`, { method: 'DELETE' }).then(() =>
          mutate()
        );
      }
      break;
    default:
      break; //TODO: view
  }
};
</script>

<template>
  <div class="flex justify-between">
    Apps:
    <button @click="creating = true">
      <FontAwesomeIcon :icon="faCirclePlus" />
    </button>
  </div>
  <SimpleList
    :data="data"
    :columns="{ id: 'Key', name: 'Name', url: 'Url' }"
    :actions="{ delete: 'Delete' }"
    @action="handleAction"
  >
    <template #id="{ data }">
      <CopyButton :text="data.id" />
    </template>
    <template #url="{ data }">
      <a target="_blank" :href="data.url" :title="data.url">
        <FontAwesomeIcon :icon="faArrowUpRightFromSquare" />
      </a>
    </template>
  </SimpleList>
  <EmbeddedModal :open="creating" @closing="creating = false">
    <div class="m-5">
      <CreateAppForm @saved="handleCreate" />
    </div>
  </EmbeddedModal>
</template>

<style scoped lang="scss"></style>
