import { useAuth0 } from '@auth0/auth0-vue';

export const useApiFetch = () => {
  const { getAccessTokenSilently } = useAuth0();

  const apiFetch = async (uri: string, options?: RequestInit) => {
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(
      new URL(uri, import.meta.env.VITE_SERVICE_URL),
      {
        ...(options ?? {}),
        headers: {
          ...(options?.headers ?? {}),
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw response.status;
    }

    return response.json();
  };

  return {
    apiFetch,
  };
};
