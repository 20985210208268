<script setup lang="ts">
import { computed } from 'vue';
import { getNode } from '@formkit/core';
import { useApiFetch } from '../composables/use-api-fetch';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

const props = defineProps<{
  config: any;
  apps: any[];
  permissions: any[];
}>();

const emit = defineEmits<{
  (e: 'saved', result: any): void;
}>();

const { apiFetch } = useApiFetch();

const groupedPermissions = computed(() => {
  const perms: Record<string, Record<string, string[]>> = {};

  props.permissions.forEach((perm) => {
    const [appValue, resourceValue, scopeValue] = (perm.value as string).split(
      ':'
    );
    if (appValue !== 'accounts') {
      if (!(appValue in perms)) {
        perms[appValue] = {};
      }
      if (!(resourceValue in perms[appValue])) {
        perms[appValue][resourceValue] = [];
      }
      if (!(scopeValue in perms[appValue][resourceValue])) {
        perms[appValue][resourceValue].push(scopeValue);
      }
    }
  });

  return perms;
});

async function handleSubmit(formData: any) {
  const apps = formData.apps;
  let permissions: string[] = [];

  for (const objKey in formData) {
    if (objKey.startsWith('perms_')) {
      permissions = permissions.concat(formData[objKey]);
    }
  }

  const result = await apiFetch(
    `/api/users/${props.config.userId}/configs/${props.config.id}`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        apps,
        permissions,
      }),
    }
  );

  emit('saved', result);
}

function toggleGroup(active: boolean, app: string, resource?: string) {
  let affectedGroups: Record<string, string[]> = {};
  if (resource) {
    affectedGroups[`${props.config.account.id}_${app}_${resource}`] =
      groupedPermissions.value[app][resource].map(
        (scope) => `${app}:${resource}:${scope}`
      );
  } else {
    for (const resource in groupedPermissions.value[app]) {
      affectedGroups[`${props.config.account.id}_${app}_${resource}`] =
        groupedPermissions.value[app][resource].map(
          (scope) => `${app}:${resource}:${scope}`
        );
    }
  }

  for (const nodeId in affectedGroups) {
    getNode(nodeId)?.input([...(active ? affectedGroups[nodeId] : [])]);
  }
}

async function handleDelete(accountId: string) {
  if (
    confirm(
      `Do you really want to delete the configuration for account "${props.config.account.name}"?`
    )
  ) {
    await apiFetch(
      `/api/users/${props.config.userId}/configs/${props.config.id}`,
      {
        method: 'DELETE',
      }
    );

    emit('saved', undefined);
  }
}
</script>

<template>
  <div class="p-4 border border-white">
    <FormKit
      type="form"
      @submit="handleSubmit"
      :submit-attrs="{ wrapperClass: 'mt-4', inputClass: 'underline' }"
    >
      <div class="grid grid-cols-[1fr_99fr] gap-4">
        <div>Account:</div>
        <div>
          {{ config.account.name }}
          <a
            href="#"
            class="inline ml-4"
            @click.prevent="handleDelete(config.account.id)"
          >
            <FontAwesomeIcon :icon="faTrashCan" />
          </a>
        </div>
        <div>Apps:</div>
        <div>
          <FormKit
            type="checkbox"
            name="apps"
            :value="config.apps"
            :options="apps.map((app) => app.id)"
            wrapperClass="w-full flex gap-2 my-1"
          />
        </div>
        <div>Permissions:</div>
        <div>
          <div
            v-for="(resources, app) in groupedPermissions"
            class="m-2 border border-white border-dashed"
          >
            {{ app }}
            <a
              href="#"
              class="underline"
              @click.prevent="(ev) => toggleGroup(true, app)"
              >all</a
            >
            &nbsp;|&nbsp;
            <a
              href="#"
              class="underline"
              @click.prevent="(ev) => toggleGroup(false, app)"
              >none</a
            >
            <div
              v-for="(scopes, resource) in resources"
              class="pl-4 m-2 border border-white border-dashed"
            >
              {{ resource }}
              <a
                href="#"
                class="underline"
                @click.prevent="(ev) => toggleGroup(true, app, resource)"
                >all</a
              >
              &nbsp;|&nbsp;
              <a
                href="#"
                class="underline"
                @click.prevent="(ev) => toggleGroup(false, app, resource)"
                >none</a
              >
              <div>
                <FormKit
                  type="checkbox"
                  :id="`${config.account.id}_${app}_${resource}`"
                  :name="`perms_${app}_${resource}`"
                  :value="config.permissions?.filter((perm: string) => perm.startsWith(`${app}:${resource}`))"
                  :options="(scopes).map((scope: string) => ({value: `${app}:${resource}:${scope}`, label: scope}))"
                  wrapperClass="pl-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormKit>
  </div>
</template>
