<script setup lang="ts">
import { useApiFetch } from '../composables/use-api-fetch';
import useSWRV from 'swrv';
import SimpleList from '../components/SimpleList.vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const { apiFetch } = useApiFetch();
const { data } = useSWRV<any[]>('/api/users', apiFetch);

const handleAction = (action: string, user: any) => {
  if (action === 'view')
    router.push({
      name: 'user',
      params: {
        id: user.user_id,
      },
    });
};
</script>

<template>
  <h1>Users:</h1>
  <SimpleList
    :columns="{ picture: '', email: 'E-Mail' }"
    :data="data"
    :actions="{ view: 'View' }"
    @action="handleAction"
  >
    <template #picture="{ data: { picture } }">
      <img alt="" :src="picture" class="w-8 h-8" />
    </template>
  </SimpleList>
</template>

<style scoped lang="scss"></style>
