import { createRouter, createWebHistory } from 'vue-router';
import { authGuard } from '@auth0/auth0-vue';
import HomeView from '../views/HomeView.vue';
import AccountsView from '../views/AccountsView.vue';
import UsersView from '../views/UsersView.vue';
import AppsView from '../views/AppsView.vue';
import UserDetailsView from '../views/UserDetailsView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: AccountsView,
    },
    {
      path: '/users',
      name: 'users',
      component: UsersView,
    },
    {
      path: '/users/:id',
      name: 'user',
      component: UserDetailsView,
    },
    {
      path: '/apps',
      name: 'apps',
      component: AppsView,
    },
  ],
});

router.beforeEach(authGuard);

export default router;
