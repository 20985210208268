import './styles.scss';

import { createApp } from 'vue';
import App from './App.vue';
import authz from './infrastructure/authz';
import router from './infrastructure/router';
import formkit from './infrastructure/formkit';

const app = createApp(App);

app.use(authz);
app.use(router);
app.use(formkit.plugin, formkit.config);

app.mount('#root');
