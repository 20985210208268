<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import { useApiFetch } from './composables/use-api-fetch';
import useSWRV from 'swrv';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const { isLoading, isAuthenticated, logout } = useAuth0();
const { apiFetch } = useApiFetch();
const {
  data: health,
  error,
  isValidating,
} = useSWRV<{ healthy: boolean }>(
  () => (isAuthenticated.value ? '/api/health' : null),
  apiFetch,
  {
    shouldRetryOnError: false,
  }
);
</script>

<template>
  <div class="flex flex-col h-dvh justify-center items-center">
    <div v-if="isLoading || isValidating">loading...</div>
    <div
      v-else-if="health?.healthy"
      class="flex flex-col w-full h-full p-4 gap-12 md:max-w-[600px] md:max-h-[800px] border border-white"
    >
      <header>
        <nav class="w-full flex gap-4">
          <RouterLink to="/accounts" active-class="underline"
            >Accounts</RouterLink
          >
          <RouterLink to="/apps" active-class="underline">Apps</RouterLink>
          <RouterLink to="/users" active-class="underline">Users</RouterLink>
          <div class="grow"></div>
          <button v-if="isAuthenticated" @click="logout()">
            <FontAwesomeIcon
              :icon="faArrowRightFromBracket"
              class="rotate-180"
            />
          </button>
        </nav>
      </header>
      <main class="overflow-y-auto">
        <RouterView />
      </main>
    </div>
    <div v-else-if="error === 403">admin-only</div>
    <div v-else-if="isAuthenticated">something is wrong</div>
    <div v-else></div>
  </div>
</template>

<style scoped lang="scss"></style>
