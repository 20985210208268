<script setup lang="ts">
defineProps<{
  open?: boolean;
}>();

const emit = defineEmits<{
  (e: 'closing'): void;
}>();
</script>

<template>
  <div
    v-if="open"
    class="fixed w-screen h-screen top-0 left-0 backdrop-blur-sm"
    @click="emit('closing')"
  >
    <div
      class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-800 border border-white"
      @click.stop
    >
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
