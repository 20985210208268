<script setup lang="ts">
import { useApiFetch } from '../composables/use-api-fetch';

const emit = defineEmits<{
  (e: 'saved', result: any): void;
}>();

const { apiFetch } = useApiFetch();

const handleSubmit = async (formData: any) => {
  const result = await apiFetch('/api/accounts', {
    method: 'POST',
    body: JSON.stringify(formData),
  });
  emit('saved', result);
};
</script>

<template>
  <FormKit
    type="form"
    @submit="handleSubmit"
    :submit-attrs="{ wrapperClass: 'mt-4', inputClass: 'underline' }"
  >
    <FormKit type="text" name="name" label="Name" validation="required" />
  </FormKit>
</template>

<style scoped lang="scss"></style>
